<template>
  <div class="container-fluid">
    <div class="row p-0 m-0">
      <div class="col-lg-3 col-md-3 col-6 pl-0">
        <!-- {{permissionExist}} -->
        <w-text-input 
          :labelName="'Role Name'"
          :fieldType="'text'"
          :disabled="!isRoleName"
          :defaultValue="roleName"
          :labelStyle="'role-page-label'"
          :newInputStyle="'role-page-input'"
          :validation="{ alpha_num: true }"
          :placeholder="'Role Name'"
          @textInputChange="getRoleName($event)"
          />
      </div>
      <div class="col-lg-3 col-md-3 col-6 mr-auto">
        <label>Permission Section</label>
         <w-dropdown
              :label-text="''"
              :selectedValue="selectedPermissionSection"
              :selectOption="permissionSectionList"
              @selectedOption="getSelectedPermissionSection($event)"
          />
      </div>
    </div>
    <div class="permission-list-card">
      <div class="permission-table-wrapper">
        <table class="permission-table w-100">
          <tr class="p-3">
            <th class="permission-table-header">Role</th>
            <th class="permission-table-header">Description</th>
            <th class="permission-table-header">Action</th>
          </tr>
          <tr v-for="(permission,index) in permissionList" :key="index">
            <td>{{permission.permissionDisplayName}}</td>
            <td>{{permission.featureName}}</td>
            <td class="permission-checkbox">
              <b-form-checkbox v-model="permission.selected" name="check-button" switch @change="checkPermission()"></b-form-checkbox>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <!-- <div class="row" >
      <div class="col-md-3 px-0 bg-white"
        style="background:rgb(239, 239, 239); height:500px;overflow-y: auto"
      >
        <p
          class="role-list"
          v-for="(tab, i) in this.permissionTable.items"
          :key="i"
        >
          <span
            :class="{ active: isActiveTab == i }"
            @click="clickOnActive(i)"
            style="display:block; padding:7px 0 7px 15px; font-size:14px"
          >
            {{ tab.role }}
          </span>
        </p>
      </div>
      <div
        class="col-md-9"
        style="background:rgb(239, 239, 239); height:500px;overflow-y: auto"
      >
        <b-card
          class="main-card mx-2 d-none"
          :class="{activeTable : isActiveTab == i}"
          v-for="(data, i) in permissionTable.items"
          :key="i"
        >
          <div>
            <p class="pl-2 mb-0 py-2">{{ data.role }}</p>
            <div class="col-md-12 p-0">
              <div class="role-table">
                <b-table
                  id="myTabel"
                  hover
                  :items="data.value"
                  :fields="permissionTable.fields"
                >
                  <template v-slot:head(selected)="item">
                    <label class="checkbox">
                      <input
                        type="checkbox"
                        :key="item"
                        v-model="data.checkbox"
                        @change="getCheckedValue(i)"
                        :name = "data.role"
                      />
                      <span class="primary"></span>
                    </label>
                  </template>
                  <template v-slot:cell(selected)="{ item }">
                    <label class="checkbox">
                      <input
                        type="checkbox"
                        v-model="item.selected"
                        :value="item.selected"
                        :key="item"
                        @change="getRowCheckedValue(i)"
                      />
                      <span class="primary"></span>
                    </label>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </div> -->
    <div class="row">
      <div class="text-center mt-3 mr-3 w-100 btn-groups">
        <!-- <span class="cancel-btn mr-3" @click="closePermissionModal"
          >Cancel</span
        >
        <span class="submit-btn" @click="savePermissions">Save</span> -->
        <w-button-input :label="'Save'" :buttonStyle="'generate-btn'" :buttonClass="'generate-btn'" class="mt-3" @buttonClicked="savePermissions()" :isDisabled="!isValidate" />
        <w-button-input :label="'Cancel'" :buttonStyle="'request-demo-style'" class="ml-3 mt-3" :buttonClass="'cancel-button'" @buttonClicked="closePermissionModal()" />
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from "../../widgets/TextInput.vue";
import Dropdown from "../../widgets/Dropdown.vue";
import { getAllPermissions } from "../../Service/UserService";
import Table from '../../widgets/Table.vue';
import ButtonInput from "../../widgets/InputButton.vue";

export default {
  components: {
    "w-text-input":TextInput,
    "w-dropdown":Dropdown,
    "w-button-input":ButtonInput,
    Table
  },
  props: ["permissionExist","isRoleName","roleName"],

  computed:{
    isValidate(){
      return this.newRoleName === ''? false : true
    }
  },
  data() {
    return {
      isActiveTab: 0,
      permissionTable: {
        items: [],
        fields: [
          { key: "selected", label: "", sortable: false },
          { key: "permissionDisplayName", label: "NAME", sortable: false },
          {
            key: "featureName",
            label: "DESCRIPTION",
            sortable: false,
          },
        ],
        allSelected: false,
      },
      permissionSectionList:[],
      permissionList:[],
      selectedPermissionSection:"",
      newRoleName:this.roleName
    };
  },
  created() {
    this.getAllPermissions();
    this.getSelectedPermissionSection("Company Administration");
  },

  methods: {
    getRoleName(event){
      console.log(event, " rowelgetRoleName")
      this.newRoleName = event
    },
    getToggleValue(event){
     console.log(event," toggle event");
    },
    clickOnActive(tab) {
      this.isActiveTab = tab;
    },
    closePermissionModal() {
      this.$emit("closePermissionModal");
    },

    // save the selected permissions
    savePermissions() {
      console.log(this.permissionTable, " permissionTable")
      var permissionSelected = [];
      this.permissionTable.items.forEach((item) => {
        item.value.forEach((el) => {
          if (el.selected) {
            permissionSelected.push(el.permissionName);
          }
        });
      });
      let data = {
        permissions:permissionSelected,
        roleDisplayName:this.newRoleName,
        roleName:this.newRoleName
      }
      console.log(data, " role permission data")
      this.$emit("createRole", data);
    },

    // get all permissions
    getAllPermissions() {
      getAllPermissions().then((res) => {
        let resp = [];
        res.forEach((el) => {
          resp.push({ ...el, selected: false });
        });

        this.permissionTable.items = [];
          
        resp.forEach((obj,id) => {
          var newIndex;
          newIndex = this.permissionTable.items.findIndex(
            (index) => index.role === obj.featureName
          );
          if (newIndex == -1) {
            this.permissionSectionList.push({
              value: obj.featureName,
              text: obj.featureName
            });
            this.permissionTable.items.push({
              role: obj.featureName,
              value: [obj],
              checkbox:false
            });
            
          } else {  
            this.permissionTable.items[newIndex].value.push(obj);
          }
        });

        // setTimeout(this.matchPermission, 500);
        this.matchPermission();

      });
    },
    getSelectedPermissionSection(event){
      this.selectedPermissionSection = event;
      console.log(event, " getSelectedPermissionSection");
      this.permissionList = [];
      this.permissionTable.items.map(permission=>{
        if(permission.role === event){
          console.log(permission, " permission.role")
          this.permissionList = permission.value
        }
      })
    },
    // permission which are already exists
    matchPermission() {
      this.permissionTable.items.forEach((data, index) => {
        this.getRowCheckedValue(index);
        if(data.role === this.selectedPermissionSection){
          console.log(data, " permission.role")
          this.permissionList = data.value
        }
        let flag = true;
        data.value.forEach((item, i) => {
          if (this.permissionExist.indexOf(item.permissionName) != -1) {
            item.selected = true;
          } else {
            item.selected = false;
            flag = false;
          }
        });
        data.checkbox = flag;
      });
      console.log(this.permissionTable, " permissionTable")
    },
  
    getCheckedValue(id) {
      this.permissionTable.items[id].value.forEach(val=>{
        val.selected = this.permissionTable.items[id].checkbox;
      })
    },
    getRowCheckedValue(id) {
      // console.log(id, "run",this.permissionTable.items[id].value)
      let flag = "";
      flag = true;
      this.permissionTable.items[id].value.forEach(val=>{
        if(!val.selected){
          flag = false
        }
      })
      this.permissionTable.items[id].checkbox = flag;
    },
    checkPermission(){
      console.log(this.permissionList, "  permission list")
    }
  },
};
</script>

<style scoped>
.activeTable {
  border:1px solid #2ebcd3;
  display:block !important;
}
/* style for scrollbar */
/* width */
::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #fff; 
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent; 
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2ebcd3; 
}

.role-list:hover{
  cursor:pointer; 
  background:rgb(239, 239, 239);
}

.active {
  background: rgb(239, 239, 239);
  border-right: 5px solid #2ebcd3;
}
.main-card {
  margin-top: 20px;
}
.main-card ::v-deep .card {
  border: none !important;
}
::v-deep .card-body {
  padding: 0 !important;
}
.cancel-btn,
.submit-btn {
  border: 1px solid #2e8cd3;
  padding: 6px 15px;
  color: #2e8cd3;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
}
.submit-btn {
  background: #2e8cd3;
  color: #fff;
}
.role-table ::v-deep .table {
  margin-bottom: 0;
}
.role-table ::v-deep .table thead tr {
  background: rgb(239, 239, 239);
}
.role-table ::v-deep .table td {
  vertical-align: middle !important;
}
.role-table ::v-deep .form-group {
  margin-bottom: 5px !important;
}
.role-table ::v-deep .table thead th {
  color: #1b1c1d;
  font-size: 13px;
}
.role-table ::v-deep .table tbody td {
  color: #1b1c1d;
  font-size: 13px;
}

.checkbox {
  height: 16px;
  width: 16px;
}
.checkbox span {
  height: 15px;
  width: 7px;
}
.checkbox span:after {
  border-right: 3px solid #fff;
  border-top: 3px solid #fff;
  height: 10px;
  left: 1px;
  top: 7px;
  width: 7px;
}
.permission-list-card{
  box-shadow: 0px 1px 2px #00000029;
  border: 1px solid #EEE6E6;
  border-radius: 5px;
  background-color: #FFFFFF;
}
.permission-table-wrapper{
  padding: 1rem;
  overflow-x:auto;
}
.permission-table-header{
  background-color: #EFEFEF;
  padding: .5rem;
  font-family: "proxima-nova", sans-serif;
}
.permission-table tr td{
  padding: .5rem;
  font-size: 13px;
  font-family: "proxima-nova", sans-serif;
  font-weight: normal;
}
</style>
<style>

.table{
  border: 0px !important;
}
.table .thead-light tr{
  background-color: #F4F4F4  !important;
}
.table .thead-light th{
  background-color: transparent;
  border:1px solid transparent;
}
.table tr:first-child td{
  border: 0px;
}
.permission-table{
  padding: .5rem;
}
.permission-checkbox > .custom-switch .custom-control-label::after{
    top: calc(.25rem + 0px);
    left: calc(-2.25rem + 0px);
    width: calc(1rem - 1px);
    height: calc(1rem - 1px);
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 16%) !important;
    border: solid 3px #FF0000 !important;
    background-color: #FF0000 !important;
}
.permission-checkbox > .custom-switch .custom-control-label::before{
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem;
    height: 11px;
    top: 6px;
    background-color: #E5E5E5 !important;
    border-color: #E5E5E5 !important;
}
.permission-checkbox > .custom-switch .custom-control-input:focus{
  outline: none !important;
  border: none !important;
}
.permission-checkbox > .custom-switch .custom-control-input:checked ~ .custom-control-label::before{
  background-color: #E5E5E5 !important;
  border-color: #E5E5E5 !important;
}
.permission-checkbox > .custom-switch .custom-control-input:checked ~ .custom-control-label::after{
  background-color: #03B952 !important;
  border-color: #03B952 !important;
}

@media screen and (max-width:380px) {
  .btn-groups button{
    max-width: 7rem !important
  }
}
</style>
